.dash {
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dash-header {
  padding: 16px;
  border-bottom: solid 3px rgb(206, 206, 206);
}
.dash h1 {
  font-weight: 400;
  color: rgb(104, 104, 104);
}
.dash-main {
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-svg {
  padding: 16px;
}

.dash-cards {
  display: flex;
  margin-top: 80px;
}

.dash-card {
  height: 128px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 32px;
  justify-content: center;
  border: solid 3px rgb(111, 151, 197);
  margin: 8px;
  border-radius: 6px;
}

.dash h3 {
  font-size: 1.5rem;
}

.dash p {
  font-size: 0.8rem;
  font-weight: 800;
  color: rgb(117, 117, 117);
}
