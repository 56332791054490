.control-panel {
  margin: 16px;
  margin-bottom: 0;
  font-size: 14px;
}

.control-panel-card {
  padding: 8px;
  display: flex;
  align-items: center;
}

.check-wrapper {
  margin-left: 16px;
}

.check-label {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.select-title {
  margin: 0 16px;
}

.select-wrapper {
  width: 320px;
  padding: 0;
  margin-left: 8px;
  z-index: 1000;
  display: flex;
}

.date-wrapper {
  margin-left: 8px;
}
.dark-select div {
  background: #222;
}
